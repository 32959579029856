<template>
  <div class="vm-banner d-flex align-center">
    <div class="swiper-container" id="lines-swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide d-flex align-center">
          <div class="vm-banner-bg" :style="{ backgroundImage }" />

          <v-container>
            <div class="header-wrap">
              <div class="bigger-header">
                <span style="font-weight: bold">Vuma. Choose extraordinary.</span>
              </div>
              <div class="sub-header">When we empower one, we empower all.</div>
            </div>
            <v-btn rounded outlined dark @click="$emit('scroll')">
              View Vacancies
            </v-btn>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  computed: {
    backgroundImage() {
      return 'url(' + require('@/assets/images/careers/banner.jpg') + ')';
    },
  },
  mounted() {
    new Swiper("#lines-swiper", {
      preloadImages: true,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      paginationClickable: true,
      loop: true,
      speed: 600,
      centeredSlides: true,
      spaceBetween: 0,
    });
  },
};
</script>

<style lang="scss">
.vm-banner {
  width: 100%;
  height: 100vh;
  background: #000;
  color: #fff;
  position: relative;

  .swiper-container {
    height: 100%;
    width: 100%;

    .swiper-wrapper {
      height: 100%;
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100%;
        background: #fff;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: #ee008c;
        }
      }
    }
  }

  .vm-banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    img {
      position: relative;
      transform: translateY(-5%);
      display: none;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
    }
  }

  .container {
    z-index: 5;

    .header-wrap {
      width: 100%;
      line-height: 1;
      margin-left: 1rem;

      &.active {
        background-color: #000;
      }

      .small-header {
        font-size: 32px;
        font-family: "Core Sans R 55", "Roboto", sans-serif;
        margin-bottom: 2rem;
      }

      .bigger-header {
        font-size: 75px;
        margin-bottom: 1.5rem;

        strong {
          font-family: "Core Sans R 55", "Roboto", sans-serif;
        }
      }

      .sub-header {
        font-size: 34px;
        margin-bottom: 3rem;
        margin-top: 3rem;
        max-width: 750px;
      }
    }

    .v-btn {
      margin-left: 1rem;

      &:before {
        opacity: 0;
        background-color: #ee008c;
      }

      &:hover {
        border-color: #ee008c;
        background-color: #ee008c;
      }
    }
  }
}

.sub-footer {
  position: absolute;
  bottom: 1rem;
  margin-left: 1rem;
}
</style>
