<template>
  <div id="high-speed">
    <v-card class="vm-tabs-careers">
      <v-tabs v-model="tab" dark grow>
        <v-tab v-for="tab in tabs" :key="tab.activetab" v-on:click="activetab = tab.activetab">
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="vm-core-tabs">
        <v-tab-item v-for="tab in tabData" :key="tab.key">
          <div v-if="activetab === 1" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-6 col-md-6">
                  <h2 v-if="activetab === 1" class="text-white" v-html="tab.title" />
                  <p class="vm-tab-content text-white" style="width: 100%; max-width: 460px;">
                    {{ tab.description }}
                  </p>
                  <v-btn @click="$emit('scroll')" data-section="vm-coverage-wrap" rounded outlined
                    class="view-vacancies-btn move-to btn-white">
                    {{ viewVacanciesText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div class="vm-tab-bg" :style="{
              backgroundImage: `${tab.backgroundImage}`,
            }"></div>
          </div>
          <div v-if="activetab === 2" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-8 col-md-8">
                  <h2 class="text-white">{{ tab.title }}</h2>
                  <p class="vm-tab-content text-white" style="width: 100%; max-width: 460px;">
                    {{ tab.description }}
                  </p>
                  <v-btn @click="$emit('scroll')" data-section="vm-coverage-wrap" rounded outlined
                    class="view-vacancies-btn move-to btn-white">
                    {{ viewVacanciesText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div class="vm-tab-bg" :style="{
              backgroundImage: `${tab.backgroundImage}`,
            }"></div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      searched: false,
      activetab: 1,
      viewVacanciesText: "View Vacancies",
      classProperty: "vm-section vm-tab-items d-flex align-center",
      tabData: [
        {
          key: "tab-employees",
          title: "We are brave. We are fast.<br/>We are respectful.",
          description:
            "We exist to empower ordinary people to do extraordinary things by providing skills development and learning programs to our employees to ensure they grow in both their professional and personal capacity. We encourage smart thinking and brave actions that challenge conventions – always reimagining the seemingly impossible.",
          backgroundImage:
            "url(" + require(`@/assets/images/careers/tab_employees.jpg`) + ")",
        },
        {
          key: "tab-culture",
          title: "We trust. We lead. We are one.",
          description:
            "Our core value system is centered around an internal belief of, because we can, we must which is why we work to ensure our value extends beyond our infrastructure by embracing the value of people, communities, and relationships with our business partners.",
          backgroundImage:
            "url(" + require(`@/assets/images/careers/tab_culture.jpg`) + ")",
        },
      ],
      tabs: [
        {
          activetab: 1,
          title: "Employees",
        },
        {
          activetab: 2,
          title: "Culture",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.vm-tabs-careers {
  .text-white {
    color: #fff;
  }

  .theme--dark.v-tabs {
    &>.v-tabs-bar {
      background-color: #ee008c;
      height: 85px;
    }

    .v-tab {
      font-size: 25px;
      font-family: "Core Sans R 35", "Roboto", sans-serif !important;
      text-transform: none;
      letter-spacing: 0.1em;
      width: 20%;
      color: #fff !important;

      &.v-tab--active {
        font-family: "Core Sans R 55", "Roboto", sans-serif !important;

        &::before {
          opacity: 0.24 !important;
        }

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #ee008c;
          position: absolute;
          bottom: -20px;
          left: 50%;
        }
      }

      &:hover::before {
        opacity: 0.24;
      }
    }

    .v-tabs-slider-wrapper {
      display: none;
    }
  }

  .vm-tab-items {
    color: #000;

    .vm-tab-bg {
      &:before {
        opacity: 0.5;
        background-color: #000;
      }
    }
  }

  .v-btn.btn-white {
    color: #fff;
    transition: all 0.2s ease-in;

    &:hover {
      background-color: #ff008e;
      border-color: #ff008e;
    }
  }
}

@media only screen and (max-width: 600px) {

  .view-vacancies-btn,
  .view-vacancies-btn span.v-btn__content {
    border-color: #ee008c;
    color: #ee008c;
  }
}
</style>
