<template>
  <v-dialog v-model="show" width="700px" scrollable>
    <v-card v-if="vacancy">
      <v-card-title class="form-header-wrapper" v-if="!applicationSubmitted">
        <div class="heading-title-wrapper">
          <h3 class="vacancy-title">{{ vacancy.title }}</h3>
          <hr class="dark" />
        </div>
      </v-card-title>
      <v-card-text class="" v-if="!applicationSubmitted && !isError">
        <h4 style="color: #333">
          Please complete the below form to apply for this role. Alternatively,
          you can send details, along with your CV and video to
          <a class="recruitment-link" href="mailto:recruitment@vumatel.co.za"
            >recruitment@vumatel.co.za</a
          >.
        </h4>

        <v-form
          ref="applicationForm"
          v-model="formValid"
          @submit.prevent="submitApplication"
        >
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="application.first_name"
                  :rules="requiredRules"
                  label="First name"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="application.last_name"
                  :rules="requiredRules"
                  label="Last name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="application.email_address"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="application.contact_number"
                  :rules="contactNumberRules"
                  label="Contact Number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="application.cv_document"
                  :rules="cvFileInputRules"
                  accept="application/pdf"
                  label="Upload CV"
                  hint="Upload a PDF file less than 5MB"
                  persistent-hint
                  required
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-file-input
                  v-model="application.introduction_video"
                  :rules="videoInputRules"
                  accept="video/*"
                  label="Upload Your Introduction Video"
                  required
                >
                  <v-tooltip slot="append" left color="#EE008C" max-width="420">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="#EE008C">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>

                    <div>
                      Create a short video where you introduce yourself,<br />list
                      your qualifications as well as your career highlights<br />
                      (previous roles, employers, etc.). <br /><br />
                      Don't forget to tell us something interesting about
                      yourself as well.
                      <br /><br />
                      <span>Video requirements:</span><br />
                      <ul>
                        <li>Less than 3 minutes</li>
                        <li>HD quality or less (720p, 480p, 360p)</li>
                        <li>Max file size of 250MB</li>
                      </ul>
                      <br />
                    </div>
                  </v-tooltip>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-checkbox v-model="application.terms_accepted" color="#ff008e" :rules="popiaRules">
                  <template v-slot:label>
                    <div>
                      By ticking this box, I agree that I have read the

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            class="recruitment-link"
                            href="https://cdn.vumatel.co.za/files/vumatel-privacy-policy.pdf"
                            @click.stop
                            v-on="on"
                          >Terms & Conditions</a>
                        </template>
                      </v-tooltip>

                      and

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            class="recruitment-link"
                            href="https://cdn.vumatel.co.za/files/vumatel-privacy-policy.pdf"
                            @click.stop
                            v-on="on"
                          >Privacy Policy</a>
                        </template>
                      </v-tooltip>. <br/>I consent to the given information being used by Vumatel to contact me about my job application.
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="text-center">
            <v-btn
              :loading="submitting"
              :disabled="!formValid"
              type="submit"
              rounded
              dark
            >
              Apply
            </v-btn>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-text v-if="applicationSubmitted && !isError">
        <v-container class="text-center">
          <v-row>
            <v-col cols="12" md="12">
              <h1 style="margin-top: 2rem; margin-bottom: 1rem; font-size: 2em;">
                Thank you for your interest in joining the Vuma family!
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <h3>
                Our team will carefully review your application and get back to
                you as soon as possible. <br /><br />We may not be able to
                respond to every applicant due to the high volume of
                applications. Therefore, you can consider your application
                unsuccessful if you do not hear from us within a 4-week period.
              </h3>
            </v-col>
          </v-row>
        </v-container>

        <v-container @click="handleClose" class="text-center">
          <v-btn rounded dark>
            Close
          </v-btn>
        </v-container>
      </v-card-text>

      <v-card-text v-if="isError">
        <v-container class="text-center">
          <v-row>
            <v-col cols="12" md="12">
              <h1 style="font-size: 2em;">
                Failed to submit application.
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <h3>
                An error occurred while submitting your application.
                Please try again later.
              </h3>
            </v-col>
          </v-row>
        </v-container>

        <v-container @click="handleClose" class="text-center">
          <v-btn rounded dark>
            Close
          </v-btn>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.recruitment-link {
  color: #ff008e;
}
</style>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    vacancy: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    application: {},
    submitting: false,
    formValid: null,
    applicationSubmitted: false,
    isError: false,
    popiaRules: [
    (value) => {
        if (value) return true;

        return "You must accept the terms and conditions before submitting the form.";
      },
    ],
    requiredRules: [
      (value) => {
        if (value) return true;

        return "This field is required.";
      },
    ],
    emailRules: [
      (value) => {
        if (value) return true;

        return "E-mail is required.";
      },
      (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Please enter a valid e-mail.";
      },
    ],
    contactNumberRules: [
      (v) => !!v || "Contact number is required",
      (value) => {
        const pattern = /^(\+?27|0)([1-8]{1})([0-9]{1})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return pattern.test(value) || "Please enter a valid contact number";
      },
    ],
    cvFileInputRules: [
      (value) => {
        if (value) return true;

        return "This field requires a PDF file less than 5MB.";
      },

      (value) => {
        return (
          !value || value.size < 5242880 || "file size should be less than 5MB"
        );
      },

      (file) => {
        if (file) {
          const fileNameSplit = file.name.split(".");
          const fileType = fileNameSplit[
            fileNameSplit.length - 1
          ].toLowerCase();
          return fileType == "pdf" || "Please upload a valid PDF file";
        }
        return "Please upload a valid PDF file";
      },
    ],
    videoInputRules: [
      (value) => {
        if (value) return true;

        return "This field is required.";
      },

      (value) => {
        return (
          !value ||
          value.size <= 262144000 ||
          "file size should be less than 250MB"
        );
      },

      (file) => {
        if (file) {
          const content_type = file.type.split("/")[0];
          return content_type == "video" || "Please upload a valid video file";
        }
        return "Please upload a valid video file";
      },
    ],
  }),
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("close", value);
      },
    },
  },
  methods: {
    async submitApplication() {
      const formValid = await this.$refs.applicationForm.validate();

      if (formValid) {
        let formData = new FormData();

        Object.entries(this.application).forEach(([key, value]) => {
          formData.append(key, value);
        });

        formData.append("vacancy", parseInt(this.vacancy.id));

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.submitting = true;

        try {
          const { status } = await this.axios.post(
            process.env.VUE_APP_PANGEA_BASE_URL + "/support/job-applications/apply/",
            formData,
            config
          );

          if (status == 201) {
            this.applicationSubmitted = true;
          }
        } catch (error) {
          console.error(error)
          this.isError = true;
          this.submitApplication = false
          this.applicationSubmitted = false;
        } finally {
          this.submitting = false
        }
      }
    },
    handleClose() {
      this.applicationSubmitted = false;
      this.application = {};
      this.isError = false
      this.$emit("close");
    },
  },
  watch: {
    show(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$refs?.applicationForm?.reset();
      }
    },
  },
};
</script>
