<template>
  <div class="vm-section vm-core d-flex align-center">
    <v-container class="text-center">
      <v-row class="justify-center">
        <v-col class="xs-12 sm-5 md-3">
          <h1>Here are some of the roles we are currently recruiting for</h1>
          <v-container>
            <v-row class="justify-center" v-if="loading && !vacancies.length">
              <div class="d-none d-sm-block" style="width: 50%">
                <div style="margin-top: 60px; margin-bottom: 60px">
                  <strong>Loading vacancies...</strong>
                </div>
                <v-progress-linear
                  style="margin-bottom: 30px"
                  color="#ff008e"
                  indeterminate
                ></v-progress-linear>
              </div>
            </v-row>

            <v-row class="justify-center" v-if="noVacanciesFound">
              <div class="d-none d-sm-block" style="width: 50%;">
                <div style="margin-top: 60px; margin-bottom: 60px">
                  <strong>There are currently no vacancies available.</strong>
                </div>
              </div>
            </v-row>

            <v-row class="justify-center" v-else>
              <div class="d-none d-sm-block" style="width: 50%;">
                <table id="vacancies">
                  <tbody>
                    <tr v-for="vacancy in vacancies" :key="vacancy.id">
                      <td class="text-start">{{ vacancy.title }}</td>
                      <td class="text-end">
                        <v-btn
                          class="apply-now"
                          @click="viewVacancy(vacancy)"
                          rounded
                          dark
                          >Apply Now</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-btn
                  style="margin-top: 50px"
                  class="apply-now"
                  @click="fetchMore"
                  rounded
                  dark
                  :loading="vacancies.length > 1 && loading"
                  :disabled="page >= pageCount"
                  :v-if="pageCount > 1"
                  >Show More</v-btn
                >
              </div>
              <div class="mobile-isp d-block d-sm-none">
                <div
                  class="isp-item"
                  v-for="vacancy in vacancies"
                  :key="vacancy.id"
                >
                  <h4>{{ vacancy.title }}</h4>
                  <p>
                    <a :href="'tel:' + vacancy.id" target="_blank"></a><br />
                    <a href="" target="_blank">Apply Now</a>
                  </p>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <vacancy-detail-dialog
      :open.sync="showVacancy"
      :vacancy="vacancy"
      v-if="vacancy"
      @apply="
        showVacancy = false;
        showApplicationForm = true;
      "
      @close="showVacancy = false"
    />
    <job-application-dialog
      v-if="vacancy"
      :open.sync="showApplicationForm"
      :vacancy="vacancy"
      @close="showApplicationForm = false"
    />
  </div>
</template>

<script>
import JobApplicationDialog from "./JobApplicationDialog.vue";
import VacancyDetailDialog from "./VacancyDetailDialog.vue";

export default {
  components: { JobApplicationDialog, VacancyDetailDialog },
  props: {
    vacancies: {
      type: Array,
      default: () => [],
    },
    pageCount: { type: Number },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    page: 1,
    showVacancy: false,
    jobApplication: {},
    showApplicationForm: false,
    vacancy: null,
  }),
  computed: {
    noVacanciesFound() {
      return this.loading == false && this.vacancies.length == 0;
    },
  },
  methods: {
    viewVacancy(vacancy) {
      this.showVacancy = true;
      this.vacancy = vacancy;
    },
    fetchMore() {
      this.page++
      this.$emit('fetch-more', this.page);
    },
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 1990,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 2356,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss">
#vacancies {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3rem;

  a {
    color: #000;
    text-decoration: none;
  }

  tr:hover a {
    color: #ff008e;
  }
}

table {
  border: 0 !important;
  width: 100% !important;
  border-collapse: collapse;
}

th,
td {
  text-align: left !important;
  padding: 16px !important;
  font-size: 19px !important;
}

tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

tr:nth-child(odd) {
  background-color: rgb(217, 217, 217) !important;
}

.v-btn.apply-now {
  font-size: 0.875rem !important;
  height: 36px !important;
  min-width: 64px !important;
  padding: 0 16px !important;
}

.vacancy-title {
  font-family: "Core Sans R 45";
  color: #ff008e;
  font-size: 25px;
  font-weight: 900;
}

hr.dark {
  border: 0.07rem solid rgba(149,150,163	);
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

hr.pink {
  border: 0.06rem solid #ff008e	;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

.heading-title-wrapper {
  float: left;
  width: 100%;
}
</style>
