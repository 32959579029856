<template>
  <v-dialog v-model="show" width="700px" scrollable>
    <v-card v-if="vacancy">
      <v-card-title class="form-header-wrapper">
        <div class="heading-title-wrapper">
          <h3 class="vacancy-title">{{ vacancy.title }}</h3>
          <hr class="dark" />
        </div>
      </v-card-title>
      <v-card-text>
        <div style="color: rgba(0, 0, 0, 0.87); font-weight: bold;">{{ vacancy.description }}</div>

        <vacancy-list title="Skills Required" :list-items="vacancy.skills_required" />
        <vacancy-list title="Responsibilities" :list-items="vacancy.responsibilities" />

        <vacancy-detail title="Experience" :content="vacancy.experience" />
        <vacancy-detail title="Valid Until" :content="expiryDate" />
      </v-card-text>

      <v-card-actions>
        <v-container class="text-center">
          <v-btn rounded dark @click="$emit('apply')">
            Apply Now
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VacancyDetail from "./VacancyDetail.vue";
import VacancyList from "./VacancyList.vue";
import moment from "moment";
export default {
  components: { VacancyList, VacancyDetail },
  name: "VacancyDetailDialog",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    vacancy: {
      type: Object,
      required: true,
    },
  },
  computed: {
    expiryDate() {
      return moment.utc(this.vacancy.expiry_date).format('Do MMMM YYYY');
    },
    show: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("close", value);
      },
    },
  },
};
</script>
