<template>
  <div>
    <h6 class="mt-8 mb-2 title">{{ title }}</h6>
    <hr class="pink" />
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 1.1rem !important;
  color: #333
}
.content {
  color: #333; 
  font-weight: 600;
}

</style>