<template>
  <div class="careers">
    <CareersBanner @scroll="scroll" />
    <CareersAbout @scroll="scroll" />
    <CareersTabs @scroll="scroll" />
    <CareersVacancies
      :page="page"
      :page-count="pageCount"
      :loading="loadingVacancies"
      :vacancies="vacancies"
      @fetch-more="fetchMore"
    />
  </div>
</template>

<script>
import $ from "jquery";
import CareersBanner from "@/components/Careers/Banner";
import CareersAbout from "@/components/Careers/About";
import CareersTabs from "@/components/Careers/Tabs";
import CareersVacancies from "@/components/Careers/Vacancies";

export default {
  name: "Careers",
  components: {
    CareersBanner,
    CareersAbout,
    CareersTabs,
    CareersVacancies,
  },
  mounted() {
    this.getOpenVacancies();
    $("html").css("overflow", "auto");
    $(".vuma-menu, .menu-overlay").removeClass("menu-show");
  },
  data: () => ({
    baseURL: process.env.VUE_APP_PANGEA_BASE_URL + "/support/job-vacancies/",
    loadingVacancies: false,
    loadingVacanciesFailed: false,
    page: 1,
    pageSize: 10,
    vacancies: [],
    totalVacancies: 0,
  }),
  computed: {
    pageCount() {
      return this.totalVacancies
        ? Math.ceil(this.totalVacancies / this.pageSize)
        : 0;
    },
  },
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 1990,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 2050,
          behavior: "smooth",
        });
      }
    },
    scrollCoverage() {
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $(".vm-coverage-wrap").offset().top - 50,
          },
          500,
          "swing"
        );
    },
    fetchMore(pageNumber) {
      this.page = pageNumber;
      this.getOpenVacancies();
    },
    async getOpenVacancies() {
      try {
        this.loadingVacancies = true;
        this.loadingVacanciesFailed = false;

        const response = await this.axios.get(
          `${this.baseURL}/?page=${this.page}&page_size=${this.pageSize}`
        );
        this.totalVacancies = response.data.count;
        this.vacancies.push(...response.data.results);
      } catch (err) {
        this.loadingVacanciesFailed = true;
      } finally {
        this.loadingVacancies = false;
      }
    },
  },
};
</script>
