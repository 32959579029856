<template>
  <div>
    <h6 class="mt-8 mb-2 title">{{ title }}</h6>
    <hr class="pink"/>
    <ul>
      <li v-for="(listItem, index) in listItems" :key="index">
        {{ listItem }}
      </li>
    </ul>
  </div>
</template>

<style scoped>
.title {
  font-weight: 600;
  font-size: 1.1rem !important;
  color: #333
}

hr {
  border: 0.07rem solid #ee008c;
  width: 100%;
  margin-bottom: 20px; 
  margin-top: 10px
}


li {
  margin-bottom: 3px;
  color: #333; 
  font-weight: 600;
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    listItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
